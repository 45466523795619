#sidebar-wrapper {
  width: 250px;
  height: 100vh; /* Full height */
  position: fixed;
  top: 0; /* Sidebar starts from the top */
  left: 0;
  z-index: 100;
  background-color: #f8f9fa;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  transition: transform 0.3s ease;
}

#sidebar-wrapper.show {
  transform: translateX(0);
}

#sidebar-wrapper.hide {
  transform: translateX(-100%);
}

.sidebar-heading {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1rem;
  background-color: #007bff;
  color: white;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.list-group-item {
  border-radius: 0;
  border: none;
  padding: 0.75rem 1.25rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  background: rgba(0, 0, 0, 0.03);
}

.list-group-item.active {
  background-color: rgba(0, 123, 255, 0.1);
  color: #007bff;
  font-weight: bold;
}

.list-group-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.btn-toggle {
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 101;
  background: rgba(0, 0, 0, 0.1);
  border: 2px solid #007bff;
  color: #007bff;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-toggle:hover {
  background-color: #007bff;
  color: white;
}

@media (max-width: 768px) {
  #sidebar-wrapper {
    width: 250px;
    height: auto;
    position: fixed;
    top: 60px; /* Adjust for header height if needed */
    transform: translateX(-100%);
  }

  .btn-toggle {
    top: 1rem;
    left: 1rem;
  }
}
