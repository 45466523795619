/* Header styles */
.header {
  width: calc(100% - 250px); /* Full width minus the width of the sidebar */
  height: 60px; /* Adjust the height as needed */
  position: fixed;
  top: 0;
  left: 250px; /* Start the header where the sidebar ends */
  background-color: #007bff;
  color: white;
  z-index: 101; /* Higher than the sidebar */
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0; /* Ensure no margin is applied */
}

/* Adjust the sidebar to be below the header */
#sidebar-wrapper {
  width: 250px;
  height: 100vh; /* Full height */
  position: fixed;
  top: 0; /* Sidebar starts from the top */
  left: 0;
  z-index: 100;
  background-color: #f8f9fa;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

/* Ensure the body and html have no default margin */
body, html {
  margin: 0;
  padding: 0;
}

/* Content wrapper adjustment */
.content-wrapper {
  margin-left: 250px; /* Match the sidebar width */
  margin-top: 60px; /* Account for the height of the header */
  padding: 20px;
  flex-grow: 1;
}

@media (max-width: 768px) {
  .header {
    width: 100%; /* Full width on small screens */
    left: 0; /* No sidebar on small screens */
  }

  #sidebar-wrapper {
    width: 250px;
    height: auto;
    position: fixed;
    top: 60px; /* Adjust to account for the header height */
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  .content-wrapper {
    margin-left: 0;
    margin-top: 60px; /* Maintain space for the header on smaller screens */
  }
}

/* Dashboard.css */
.dashboard-container {
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  margin-left: 250px; /* Sidebar width */
  margin-top: 60px; /* Header height */
  padding: 20px;
}

.card {
  border: 1px solid #ddd;
}

.card-title {
  font-size: 1.2rem;
}

.card-text {
  font-size: 1.5rem;
}

.row {
  margin-top: 20px;
}


.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
  border-width: 0.3em;
}

.mt-3 {
  margin-top: 1rem;
}

.text-center {
  text-align: center;
}
