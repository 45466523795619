/* PaymentConfirmationPage.css */
.container {
    margin-top: 20px;
  }
  
  .table {
    width: 100%;
  }
  
  .table th, .table td {
    text-align: left;
  }
  

  /* src/UserPage.css */
.app-container {
  display: flex;
}

.main-content {
  flex-grow: 1;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
  border-width: 0.3em;
}

.mt-3 {
  margin-top: 1rem;
}

.text-center {
  text-align: center;
}



.table-bordered {
  border: 3px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 4px solid #dee2e6;
}
